// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Caneca_mugSelector__\\+d4Mu {\n    list-style: none;\n    background-color: #fff;\n    border-radius: 12px;\n    padding: 14px;\n    width: 150px;\n    /* box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.2); */\n    max-height: 200px;\n    overflow-y: auto;\n    border: 1px solid #e1e1e1;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 12px;\n    justify-content: center;\n}\n\n.Caneca_mugOption__\\+x08h {\n    padding: 6px 0px;\n    cursor: pointer;\n    width: 50px;\n    border-bottom: 2px solid #e0e0e0;\n}\n\n.Caneca_mugOption__\\+x08h img {\n    width: 100%;\n}\n\n.Caneca_mugOption__\\+x08h:hover, .Caneca_mugOptionActive__j9e9P {\n    border-color: #000;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/Caneca.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,kDAAkD;IAClD,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,gCAAgC;AACpC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".mugSelector {\n    list-style: none;\n    background-color: #fff;\n    border-radius: 12px;\n    padding: 14px;\n    width: 150px;\n    /* box-shadow: 0px 12px 22px rgba(0, 0, 0, 0.2); */\n    max-height: 200px;\n    overflow-y: auto;\n    border: 1px solid #e1e1e1;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 12px;\n    justify-content: center;\n}\n\n.mugOption {\n    padding: 6px 0px;\n    cursor: pointer;\n    width: 50px;\n    border-bottom: 2px solid #e0e0e0;\n}\n\n.mugOption img {\n    width: 100%;\n}\n\n.mugOption:hover, .mugOptionActive {\n    border-color: #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mugSelector": "Caneca_mugSelector__+d4Mu",
	"mugOption": "Caneca_mugOption__+x08h",
	"mugOptionActive": "Caneca_mugOptionActive__j9e9P"
};
export default ___CSS_LOADER_EXPORT___;
